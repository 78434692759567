import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule, Provider } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { Address } from '@app/states/case/case.model';
import { FormlyFieldConfig, FormlyModule, FORMLY_CONFIG } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { PopupModule } from '@progress/kendo-angular-popup';
import { DirectiveModule } from '@shared/directives/directive.module';
import { TranslateExtensionFactory } from './extension/translate.extension';
import { KendoCardField } from './fields/card.field';
import { KendoCheckboxField } from './fields/checkbox.field';
import { KendoDatePickerField } from './fields/datepicker.field';
import { KendoDropDownField } from './fields/dropdown.field';
import { KendoExpandableField } from './fields/expandable.field';
import { KendoAccordionField } from './fields/accordion.field';
import { KendoGroupField } from './fields/group.field';
import { KendoImageField } from './fields/image.field';
import { KendoLocationSearchField } from './fields/location-search.field';
import { KendoNumericTextBoxField } from './fields/numeric-textbox.field';
import { KendoCheckboxComplexField } from './fields/checkbox-complex.field';
import { KendoOutputField } from './fields/output.field';
import { KendoPriceOutputField } from './fields/price-output.field';
import { KendoPasswordField } from './fields/password.field';
import { KendoPopupField } from './fields/popup.field';
import { KendoRadioField } from './fields/radio.field';
import { KendoTextBoxField } from './fields/textbox.field';
import { FormParsePipe } from './form-parse.pipe';
import { alphaNumericValidator } from './validation/alphanumeric.validation';
import { codeValidator } from './validation/code.validation';
import { emailValidator } from './validation/email.validation';
import { phoneValidator } from './validation/phone.validation';
import { postalCodeValidator } from './validation/postal-code.validation';
import { specialCaseValidator } from './validation/special-case.validation';
import { KendoFormFieldWrapper } from './wrapper/kendo-form-field.wrapper';
import { KendoSeparatorWrapperComponent } from './wrapper/kendo-separator.wrapper';
import { KendoInfoField } from './fields/info.field';
import { KendoIncentiveField } from './fields/incentive.field';
import { KendoSubmitButtonField } from './fields/submit-button.field';
import { verificationValidator } from './validation/verification.validation';
import { KendoMaskedTextBoxField } from './fields/masked-textbox.field';
import { ibanValidator } from './validation/iban.validation';
import { sepaValidator } from './validation/sepa.validation';
import { MultiBindExtensionFactory } from './extension/combine.extension';
import { EtrackerFormWrapper } from './wrapper/etracker.wrapper';
import { KendoKKinkOptionField } from './fields/kkink-option.field';
import { PipesModule } from '@shared/pipes/pipes.module';
import { ButtonField } from './fields/button.field';
import { KendoInfoIconField } from './fields/info-icon.field';
import { ParsePlaceholdersWrapper } from './wrapper/parse-placeholders.wrapper';
import { ParsePaceholdersPipe } from '@shared/pipes/parse-placeholders.pipe';
import { AnchorField } from './fields/anchor.field';
import { ibanBlzWhitelistValidator } from './validation/iban-blz-whitelist.validation';
import { registerRtpAccordionPreset } from '@shared/ruv-forms/presets/rtp-onboarding/accordion.preset';
import { FormlyPresetModule } from '@ngx-formly/core/preset';
import { registerRtpCompletedPreset } from '@shared/ruv-forms/presets/rtp-onboarding/completed.preset';
import { RepeatTypeComponent } from './fields/repeat-section.field';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { FormattedTextFieldComponent } from './fields/formatted-text.field';
import { fastResponseValidator } from './validation/fast-response.validation';

const WRAPPERS = [EtrackerFormWrapper, KendoFormFieldWrapper, KendoSeparatorWrapperComponent, ParsePlaceholdersWrapper];

const FIELDS = [
  KendoGroupField,
  KendoDatePickerField,
  KendoTextBoxField,
  KendoMaskedTextBoxField,
  KendoPasswordField,
  KendoNumericTextBoxField,
  KendoRadioField,
  KendoCheckboxField,
  KendoCheckboxComplexField,
  KendoDropDownField,
  KendoLocationSearchField,
  KendoExpandableField,
  KendoAccordionField,
  KendoCardField,
  KendoPopupField,
  KendoOutputField,
  KendoPriceOutputField,
  KendoImageField,
  KendoInfoField,
  KendoIncentiveField,
  KendoSubmitButtonField,
  KendoKKinkOptionField,
  ButtonField,
  AnchorField,
  KendoInfoIconField,
  RepeatTypeComponent,
  FormattedTextFieldComponent,
];

export interface RuVFormsModuleConfig {
  loader: Provider;
}

@NgModule({
  declarations: [...FIELDS, ...WRAPPERS, FormParsePipe],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DateInputsModule,
    DropDownsModule,
    LayoutModule,
    ButtonsModule,
    LabelModule,
    InputsModule,
    PopupModule,
    DirectiveModule,
    TranslateModule.forChild(),
    FormlySelectModule,
    PipesModule,
    FormlyPresetModule,
    FormlyModule.forRoot({
      extras: {
        resetFieldOnHide: false,
      },
      wrappers: [
        {
          name: 'eTracker-form-wrapper',
          component: EtrackerFormWrapper,
        },
        {
          name: 'kendo-form-field-wrapper',
          component: KendoFormFieldWrapper,
        },
        {
          name: 'kendo-separator-wrapper',
          component: KendoSeparatorWrapperComponent,
        },
        {
          name: 'parse-placeholder-wrapper',
          component: ParsePlaceholdersWrapper,
        },
      ],
      types: [
        {
          name: 'submit-button',
          component: KendoSubmitButtonField,
        },
        {
          name: 'button',
          component: ButtonField,
        },
        {
          name: 'anchor',
          component: AnchorField,
        },
        {
          name: 'group',
          component: KendoGroupField,
        },
        {
          name: 'datepicker',
          component: KendoDatePickerField,
          wrappers: ['kendo-form-field-wrapper', 'eTracker-form-wrapper'],
        },
        {
          name: 'text',
          component: KendoTextBoxField,
          wrappers: ['kendo-form-field-wrapper', 'eTracker-form-wrapper'],
        },
        {
          name: 'masked',
          component: KendoMaskedTextBoxField,
          wrappers: ['kendo-form-field-wrapper', 'eTracker-form-wrapper'],
        },
        {
          name: 'formatted',
          component: FormattedTextFieldComponent,
          wrappers: ['kendo-form-field-wrapper', 'eTracker-form-wrapper'],
        },
        {
          name: 'phone',
          extends: 'text',
          defaultOptions: {
            modelOptions: {
              updateOn: 'blur',
            },
            validators: {
              validation: ['phone'],
            },
          },
        },
        {
          name: 'email',
          extends: 'text',
          defaultOptions: {
            modelOptions: {
              updateOn: 'blur',
            },
            validators: {
              validation: ['email'],
            },
          },
        },
        {
          name: 'iban',
          extends: 'masked',
          defaultOptions: {
            props: {
              mask: 'LL00 0000 0000 0000 0000 00',
            },
            modelOptions: {
              updateOn: 'blur',
            },
            validators: {
              validation: ['iban'],
            },
          },
        },
        {
          name: 'general-iban',
          extends: 'formatted',
          defaultOptions: {
            props: {
              mask: 'UU00 AAAA 0000 0000 0000 9999 9999 9999 99',
            },
            modelOptions: {
              updateOn: 'blur',
            },
          },
        },
        {
          name: 'password',
          component: KendoPasswordField,
          wrappers: ['kendo-form-field-wrapper', 'eTracker-form-wrapper'],
        },
        {
          name: 'numeric',
          component: KendoNumericTextBoxField,
          wrappers: ['kendo-form-field-wrapper', 'eTracker-form-wrapper'],
        },
        {
          name: 'radio',
          component: KendoRadioField,
          wrappers: ['kendo-form-field-wrapper', 'eTracker-form-wrapper'],
        },
        {
          name: 'kkink-options',
          component: KendoKKinkOptionField,
          wrappers: ['kendo-form-field-wrapper', 'eTracker-form-wrapper'],
        },
        {
          name: 'checkbox',
          component: KendoCheckboxField,
          wrappers: ['kendo-form-field-wrapper', 'eTracker-form-wrapper'],
        },
        {
          name: 'checkbox-complex',
          component: KendoCheckboxComplexField,
          wrappers: ['kendo-form-field-wrapper', 'eTracker-form-wrapper'],
        },
        {
          name: 'dropdown',
          component: KendoDropDownField,
          wrappers: ['kendo-form-field-wrapper', 'eTracker-form-wrapper'],
        },
        {
          name: 'location-search',
          component: KendoLocationSearchField,
          wrappers: ['kendo-form-field-wrapper', 'eTracker-form-wrapper'],
        },
        {
          name: 'popup',
          component: KendoPopupField,
          wrappers: ['kendo-form-field-wrapper'],
        },
        {
          name: 'output',
          component: KendoOutputField,
          wrappers: ['kendo-form-field-wrapper'],
        },
        {
          name: 'price-output',
          component: KendoPriceOutputField,
          wrappers: ['kendo-form-field-wrapper'],
        },
        {
          name: 'image',
          component: KendoImageField,
          wrappers: ['kendo-form-field-wrapper'],
        },
        {
          name: 'info',
          component: KendoInfoField,
          wrappers: ['kendo-form-field-wrapper'],
        },
        {
          name: 'info-icon',
          component: KendoInfoIconField,
        },
        {
          name: 'incentive',
          component: KendoIncentiveField,
          wrappers: ['kendo-form-field-wrapper'],
        },
        {
          name: 'expandable',
          component: KendoExpandableField,
          wrappers: ['kendo-form-field-wrapper'],
        },
        { name: 'repeat', component: RepeatTypeComponent },
        {
          name: 'accordion',
          component: KendoAccordionField,
        },
        {
          name: 'card',
          component: KendoCardField,
        },
        {
          name: 'address',
          extends: 'group',
          defaultOptions: {
            props: {
              label: 'FORM.ADDRESS.LABEL',
              labelClass: 'col-12',
            },
            fieldGroupClassName: 'grid',
            fieldGroup: [
              {
                key: 'countryCode',
                type: 'dropdown',
                className: 'col-4',
                defaultValue: 'DE',
                props: {
                  translate: true,
                  options: [
                    { label: 'FORM.COUNTRIES.ANDORRA', value: 'AD' },
                    { label: 'FORM.COUNTRIES.BELGIUM', value: 'BE' },
                    { label: 'FORM.COUNTRIES.BRAZIL', value: 'BR' },
                    { label: 'FORM.COUNTRIES.DENMARK', value: 'DK' },
                    { label: 'FORM.COUNTRIES.GERMANY', value: 'DE' },
                    { label: 'FORM.COUNTRIES.FINLAND', value: 'FI' },
                    { label: 'FORM.COUNTRIES.FRANCE', value: 'FR' },
                    { label: 'FORM.COUNTRIES.GREENLAND', value: 'GL' },
                    {
                      label: 'FORM.COUNTRIES.GREAT_BRITAIN',
                      value: 'GB',
                    },
                    { label: 'FORM.COUNTRIES.ITALY', value: 'IT' },
                    {
                      label: 'FORM.COUNTRIES.LICHTENSTEIN',
                      value: 'LI',
                    },
                    { label: 'FORM.COUNTRIES.LUXEMBOURG', value: 'LU' },
                    { label: 'FORM.COUNTRIES.MONACO', value: 'MC' },
                    {
                      label: 'FORM.COUNTRIES.NETHERLANDS',
                      value: 'NL',
                    },
                    { label: 'FORM.COUNTRIES.NORWAY', value: 'NO' },
                    { label: 'FORM.COUNTRIES.AUSTRIA', value: 'AT' },
                    { label: 'FORM.COUNTRIES.POLAND', value: 'PL' },
                    { label: 'FORM.COUNTRIES.PORTUGAL', value: 'PO' },
                    {
                      label: 'FORM.COUNTRIES.PUERTO_RICO',
                      value: 'PR',
                    },
                    { label: 'FORM.COUNTRIES.SWEDEN', value: 'SW' },
                    {
                      label: 'FORM.COUNTRIES.SWITZERLAND',
                      value: 'CH',
                    },
                    { label: 'FORM.COUNTRIES.SLOVAKIA', value: 'SK' },
                    { label: 'FORM.COUNTRIES.SPAIN', value: 'SP' },
                    {
                      label: 'FORM.COUNTRIES.CZECH_REPUBLIC',
                      value: 'CZ',
                    },
                    { label: 'FORM.COUNTRIES.HUNGARY', value: 'HU' },
                    { label: 'FORM.COUNTRIES.USA', value: 'US' },
                  ],
                  eTrackerObject: 'Adresse',
                },
                expressions: {
                  className: (field) => {
                    return field.parent?.props?.hideSearch ? 'col-12' : 'col-4';
                  },
                },
              },
              {
                type: 'location-search',
                className: 'col-8',
                props: {
                  placeholder: 'FORM.ADDRESS.SEARCH.PLACEHOLDER',
                  emptyDataText: 'FORM.ADDRESS.SEARCH.NO_DATA',
                  eTrackerObject: 'Adresse',
                  translate: true,
                  filterDelayInMs: 800,
                  minFilterLength: 3,
                  selection: (field: FormlyFieldConfig, event: any | undefined) => {
                    field?.form?.get('street')?.setValue('');
                    field?.form?.get('streetNumber')?.setValue('');
                    field?.form?.get('postalCode')?.setValue('');
                    field?.form?.get('city')?.setValue('');
                    field?.form?.get('additionalAddressInfo')?.setValue('');

                    const selectedAddress = event?.value as Address;
                    if (selectedAddress) {
                      for (const [key, value] of Object.entries(selectedAddress)) {
                        if (key === 'additionalAddressInfo') {
                          // Only customer should fill this out.
                          continue;
                        }
                        field?.form?.get(key)?.setValue(value);
                      }
                    }
                  },
                },
                expressions: {
                  'props.country': 'model.countryCode',
                  hide: (field) => {
                    return field.parent?.props?.hideSearch ?? false;
                  },
                },
              },
              {
                key: 'street',
                type: 'text',
                className: 'col-9',
                defaultValue: null,
                validators: {
                  validation: ['special'],
                },
                props: {
                  placeholder: 'FORM.ADDRESS.STREET_NAME.PLACEHOLDER',
                  translate: true,
                  eTrackerObject: 'Adresse',
                },
                expressions: {
                  'props.required': (field) => field.parent?.props?.required,
                },
              },
              {
                key: 'streetNumber',
                type: 'text',
                className: 'col-3',
                defaultValue: null,
                validators: {
                  validation: ['alphaNumeric'],
                },
                props: {
                  placeholder: 'FORM.ADDRESS.STREET_NR.PLACEHOLDER',
                  translate: true,
                  eTrackerObject: 'Adresse',
                },
                expressions: {
                  'props.required': (field) => field.parent?.props?.required,
                },
              },
              {
                key: 'postalCode',
                type: 'text',
                className: 'col-3',
                defaultValue: null,
                validators: {
                  validation: ['alphaNumeric'],
                },
                props: {
                  placeholder: 'FORM.ADDRESS.POSTAL_CODE.PLACEHOLDER',
                  translate: true,
                  eTrackerObject: 'Adresse',
                },
                expressions: {
                  'props.required': (field) => field.parent?.props?.required,
                },
              },
              {
                key: 'city',
                type: 'text',
                className: 'col-9',
                defaultValue: null,
                validators: {
                  validation: ['special'],
                },
                props: {
                  placeholder: 'FORM.ADDRESS.CITY.PLACEHOLDER',
                  translate: true,
                  eTrackerObject: 'Adresse',
                },
                expressions: {
                  'props.required': (field) => field.parent?.props?.required,
                },
              },
              {
                key: 'additionalAddressInfo',
                type: 'text',
                className: 'col-12',
                defaultValue: null,
                props: {
                  placeholder: 'FORM.ADDRESS.ADDITIONAL_ADDRESS.PLACEHOLDER',
                  translate: true,
                  maxLength: 100,
                  description: 'FORM.ADDRESS.ADDITIONAL_ADDRESS.HINT',
                  eTrackerObject: 'Adresse',
                },
                expressions: {
                  hide: (field) => {
                    return !(field.parent?.props?.showAdditionalAddressInfo ?? false);
                  },
                },
                hooks: {
                  onInit: (field) => {
                    if (!field.parent?.props?.showAdditionalAddressInfo) {
                      field.model.additionalAddressInfo = null; // if the field is not shown, we want always to return null on submit
                    }
                  },
                },
              },
            ],
          },
        },
      ],
      validators: [
        { name: 'code', validation: codeValidator },
        { name: 'email', validation: emailValidator },
        { name: 'postalCode', validation: postalCodeValidator },
        { name: 'phone', validation: phoneValidator },
        { name: 'iban', validation: ibanValidator },
        { name: 'ibanBlzWhitelist', validation: ibanBlzWhitelistValidator },
        { name: 'special', validation: specialCaseValidator },
        { name: 'alphaNumeric', validation: alphaNumericValidator },
        { name: 'requiredTrue', validation: Validators.requiredTrue },
        { name: 'verification', validation: verificationValidator },
        { name: 'fast_response', validation: fastResponseValidator },
        {
          name: 'sepa',
          validation: sepaValidator,
          options: { allowedCountryCodes: [] }, // Empty array for allowedCountryCodes allows all country codes
        },
      ],
      presets: [registerRtpAccordionPreset(), registerRtpCompletedPreset()],
    }),
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  providers: [
    CurrencyPipe,
    ParsePaceholdersPipe,
    {
      provide: FORMLY_CONFIG,
      multi: true,
      useFactory: TranslateExtensionFactory,
      deps: [TranslateService],
    },
    {
      provide: FORMLY_CONFIG,
      multi: true,
      useFactory: MultiBindExtensionFactory,
    },
    provideNgxMask(),
  ],
  exports: [FormlyModule, ReactiveFormsModule, ...FIELDS, ...WRAPPERS],
})
export class RuVFormsModule {}
