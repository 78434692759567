import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Case, CaseType } from './case.model';
import { EMPTY_CASE } from './default/default-case.factory';
import { CaseHelper } from './case-helper';
import { DefaultCaseHelper } from './default/default-case-helper';
import { IbanCaseHelper } from './iban/iban-case-helper';
import { SwitcherCaseHelper } from './switcher/switcher-case-helper';
import { KKinkCaseHelper } from './kkink/kkink-case-helper';
import { FormType } from '@shared/ruv-forms/form-loader.service';
import { GymCaseHelper } from './gym/gym-case-helper';
import { FastResponseHelper } from './fast-response/fast-response-helper';

@Injectable({
  providedIn: 'root',
})
export class CaseStateService {
  private state$ = new BehaviorSubject<Case>(EMPTY_CASE);
  private _caseHelper = new CaseHelper<Case>();
  private _formsList: FormType[] = [];

  public get case$() {
    return this.state$.asObservable();
  }

  public get caseHelper() {
    return this._caseHelper;
  }

  public get formsList() {
    return this._formsList;
  }

  public setCase(caseObject: Case) {
    this._caseHelper = GetCaseHelper(caseObject.journeyName);
    this._formsList = GetFormsList(caseObject.journeyName);
    this.state$.next(caseObject);
  }
}

function GetCaseHelper(journeyName: CaseType | undefined) {
  switch (journeyName) {
    case CaseType.IBAN:
      return new IbanCaseHelper();
    case CaseType.SWITCHER:
    case CaseType.GENAI:
      return new SwitcherCaseHelper();
    case CaseType.KKINK:
      return new KKinkCaseHelper();
    case CaseType.GYM:
      return new GymCaseHelper();
    case CaseType.FAST_RESPONSE:
      return new FastResponseHelper();
      break;
    default:
      return new DefaultCaseHelper();
  }
}

function GetFormsList(journeyName: CaseType | undefined) {
  switch (journeyName) {
    case CaseType.KKINK:
      return [FormType.UseCase, FormType.Confirmation];
    default:
      return [FormType.UseCase];
  }
}
