import { StringUtil } from '@shared/string-util';
import { PhoneUtil } from '@shared/phone-util';
import {
  Consent,
  ConsentData,
  ConsentType,
  ContactInfo,
  DefaultCase,
} from '@app/states/case/default/default-case.model';
import { FormlyFieldConfig } from '@ngx-formly/core';
import isEqual from 'lodash.isequal';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CaseHelper } from '../case-helper';
import { CaseType } from '../case.model';

dayjs.extend(customParseFormat);

export class DefaultCaseHelper<CT extends DefaultCase = DefaultCase> extends CaseHelper<CT> {
  public override preProcessCase(caseObject: CT): CT {
    const { customer } = caseObject;

    // Safe the email address the customer was contacted by for later use.
    customer.contactEmail = customer.private?.emailAddress;

    // Set default country code to DE if empty
    if (customer.address) {
      customer.address = {
        ...customer.address,
        countryCode: StringUtil.isEmpty(customer.address?.countryCode) ? 'DE' : customer?.address?.countryCode,
      };
    }

    // Supported states are only NO_COMMENT and ACCEPTED, so DENIED will be converted to NO_COMMENT
    customer.consent = {
      phone: this.preProcessConsentData(customer.consent?.phone),
      email: this.preProcessConsentData(customer.consent?.email),
    };

    // only for rtp-onboarding case
    if (caseObject.journeyName === CaseType.RTP) {
      // set fallback values for customData
      const BANKING_NAME_FALLBACK = 'Bank';
      const CONTRACT_TYPE_FALLBACK = 'Versicherung';
      const bankingName = caseObject.customData?.bankingName
        ? caseObject.customData.bankingName
        : BANKING_NAME_FALLBACK;
      const contractType = caseObject.customData?.contractType
        ? caseObject.customData.contractType
        : CONTRACT_TYPE_FALLBACK;
      caseObject.customData = {
        ...caseObject.customData,
        bankingName: bankingName,
        contractType: contractType,
      };
    }

    return caseObject;
  }

  public override postProcessCase(caseObject: CT): CT {
    const { customer } = caseObject;

    if (customer.private) {
      customer.private = {
        ...customer.private,
        phoneNumber: PhoneUtil.toE164PhoneNumber(customer.private?.phoneNumber),
        mobileNumber: PhoneUtil.toE164PhoneNumber(customer.private?.mobileNumber),
      };
    }

    if (customer.business) {
      customer.business = {
        ...customer.business,
        phoneNumber: PhoneUtil.toE164PhoneNumber(customer.business?.phoneNumber),
        mobileNumber: PhoneUtil.toE164PhoneNumber(customer.business?.mobileNumber),
      };
    }
    // only for rtp-onboarding case
    if (caseObject.journeyName === CaseType.RTP) {
      type rtpCustomData = Record<string, string> & {
        rtpConsentCustomerAgreement?: string;
        rtpConsentSpecialConditions?: string;
      };
      // remove temporary rtpConsent parts
      const {
        rtpConsentCustomerAgreement: customerAgreement,
        rtpConsentSpecialConditions: specialConditions,
        ...customDataWithoutTempFields
      } = caseObject.customData as rtpCustomData;
      // merge temporary rtpConsent parts
      const isRtpConsentAccepted =
        customerAgreement === ConsentType.ACCEPTED && specialConditions === ConsentType.ACCEPTED;
      caseObject.customData = {
        ...customDataWithoutTempFields,
        rtpConsent: isRtpConsentAccepted ? ConsentType.ACCEPTED : ConsentType.DENIED,
      };
    }

    // If email or phone was emptied after ACCEPTED, reset consent to NO_COMMENT, otherwise DOI will fail.
    if (customer.consent) {
      customer.consent = {
        phone: {
          ...customer.consent.phone,
          customerConsentUpdateAt: dayjs().toISOString(),
          customerConsent:
            StringUtil.isEmpty(customer.private?.phoneNumber) &&
            StringUtil.isEmpty(customer.private?.mobileNumber) &&
            StringUtil.isEmpty(customer.business?.phoneNumber) &&
            StringUtil.isEmpty(customer.business?.mobileNumber)
              ? ConsentType.NO_COMMENT
              : customer.consent.phone.customerConsent,
        },
        email: {
          ...customer.consent.email,
          customerConsentUpdateAt: dayjs().toISOString(),
          customerConsent:
            StringUtil.isEmpty(customer.private?.emailAddress) && StringUtil.isEmpty(customer.business?.emailAddress)
              ? ConsentType.NO_COMMENT
              : customer.consent.email.customerConsent,
        },
      };
    }

    return caseObject;
  }

  public resetConsentFn(formState: any, consent: ConsentType, field: FormlyFieldConfig) {
    if (field.hide === false) {
      return field.defaultValue;
    }
    return consent;
  }

  public requiredEmailFn(formState: any, model: CT, field?: FormlyFieldConfig) {
    const initialCustomer = formState.initialModel.customer;
    const currentCustomer = model.customer;

    return (
      (this.hasPhoneNumberChanged(initialCustomer.private?.mobileNumber, currentCustomer.private?.mobileNumber) ||
        this.hasPhoneNumberChanged(initialCustomer.private?.phoneNumber, currentCustomer.private?.phoneNumber) ||
        this.hasPhoneNumberChanged(initialCustomer.business?.mobileNumber, currentCustomer.business?.mobileNumber) ||
        this.hasPhoneNumberChanged(initialCustomer.business?.phoneNumber, currentCustomer.business?.phoneNumber) ||
        initialCustomer.consent.phone.customerConsent === ConsentType.NO_COMMENT) &&
      currentCustomer.consent.phone.customerConsent === ConsentType.ACCEPTED &&
      StringUtil.isEmpty(currentCustomer.private?.emailAddress)
    );
  }

  public checkEmailConsentFn(formState: any, model: DefaultCase) {
    const initialPrivateContact = formState.initialModel.customer.private as ContactInfo;
    const initialConsent = formState.initialModel.customer.consent as Consent;
    const currentPrivateContact = model.customer.private as ContactInfo;
    const currentBusinessContact = model.customer.business as ContactInfo;

    return (
      (StringUtil.isEmpty(currentPrivateContact.emailAddress) &&
        StringUtil.isEmpty(currentBusinessContact.emailAddress)) ||
      (initialConsent.email.customerConsent === ConsentType.ACCEPTED &&
        initialPrivateContact.emailAddress === currentPrivateContact.emailAddress)
    );
  }

  public hasConsentedMailDew(formstate: any, model: CT) {
    return model.customer.consent.email.customerConsent === ConsentType.ACCEPTED;
  }

  public checkPhoneConsentFn(formState: any, model: CT) {
    const initialPrivateContact = formState.initialModel.customer.private as ContactInfo;
    const initialBusinessContact = formState.initialModel.customer.business as ContactInfo;
    const initialConsent = formState.initialModel.customer.consent as Consent;

    const currentPrivateContact = model.customer.private as ContactInfo;
    const currentBusinessContact = model.customer.business as ContactInfo;

    return (
      (StringUtil.isEmpty(currentPrivateContact.phoneNumber) &&
        StringUtil.isEmpty(currentPrivateContact.mobileNumber) &&
        StringUtil.isEmpty(currentBusinessContact.phoneNumber) &&
        StringUtil.isEmpty(currentBusinessContact.mobileNumber)) ||
      (initialConsent.phone.customerConsent === ConsentType.ACCEPTED &&
        (initialPrivateContact.phoneNumber === currentPrivateContact.phoneNumber ||
          StringUtil.isEmpty(currentPrivateContact.phoneNumber)) &&
        (initialPrivateContact.mobileNumber === currentPrivateContact.mobileNumber ||
          StringUtil.isEmpty(currentPrivateContact.mobileNumber)) &&
        (initialBusinessContact.phoneNumber === currentBusinessContact.phoneNumber ||
          StringUtil.isEmpty(currentBusinessContact.phoneNumber)) &&
        (initialBusinessContact.mobileNumber === currentBusinessContact.mobileNumber ||
          StringUtil.isEmpty(currentBusinessContact.mobileNumber)))
    );
  }

  public hasEmailFn(formstate: any, model: CT) {
    const { customer } = model;

    return !StringUtil.isEmpty(customer.private?.emailAddress) || !StringUtil.isEmpty(customer.business?.emailAddress);
  }

  protected preProcessConsentData(consentData: ConsentData | undefined): ConsentData {
    if (!consentData) {
      return { customerConsent: ConsentType.NO_COMMENT };
    }
    return consentData.customerConsent === ConsentType.DENIED
      ? { ...consentData, customerConsent: ConsentType.NO_COMMENT }
      : { ...consentData };
  }

  protected hasPhoneNumberChanged(originalPhoneNumber: string, currentPhoneNumber: string | undefined) {
    return !isEqual(originalPhoneNumber, currentPhoneNumber) && !StringUtil.isEmpty(currentPhoneNumber);
  }
}
